<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
          id="ChooseNotice"
          v-if="(ChooseID.length > 0)"
          color="info"
          :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseID.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseID = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex justify-content-between align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Offer/RaffleTicket') }}</h5>
            </CCol>
            <CCol col="6">
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" @click="AddIssueEvent()">
                  {{ $t('Global.Add') + '發送事件' }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
            id="RaffleTicketList"
            :items="List"
            :fields="Field"
            :loading="Loading"
            :noItemsView="noItemsView"
            responsive
            hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-DiscountTicket-' + item._id" :value="item._id" v-model="ChooseID">
                </label>
              </td>
            </template>
            <template #Event="{item}">
              <td>
                {{ $t('Offer.EventTriggerRaffleIssueOptions.' + item.Event.TriggerIssue) }}
              </td>
            </template>
            <template #TimeRange="{item}">
              <td>
                {{ $t('Offer.StartTime') }}: {{ $dayjs(item.StartTime).format('YYYY-MM-DD HH:mm:ss') }}<br>
                {{ $t('Offer.EndTime') }}: {{ $dayjs(item.EndTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 'Y' ? 'success' : 'danger')">
                  {{ $t('Offer.StatusOptions.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{item, index}">
              <td>
                <CButton v-if="item.Status === 'Y' && item.Event.TriggerIssue === 'NA'" color="success" size="sm" class="mr-1" v-c-tooltip="$t('Global.Send')" @click="IssueTicket(item)">
                  <CIcon name="cil-paper-plane" class="c-icon-custom-size" />
                </CButton>
                <CButton color="info" size="sm" class="mr-1" v-c-tooltip="$t('Global.Edit')" @click="EditIssueEvent(index)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size" />
                </CButton>
                <CButton color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Global.Delete')" @click="DeleteModal(item._id)">
                  <CIcon name="cil-trash" class="c-icon-custom-size" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
            :activePage.sync="Pagination.Page"
            :pages.sync="Pagination.TotalPages"
            @update:activePage="GetList()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="CreateModal" class="ActionModel" :show.sync="CreateModal" size="lg" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ Action === 'Edit' ? '編輯' : '新增' }}發送條件</h5>
          <CButtonClose @click="CreateModal = false"/>
        </header>
      </template>
      <div v-if="CurrentTicket" class="mb-5">
        <CInput v-model="CurrentTicket.Name" :label="'發送條件名稱'" type="text" />
        <CTextarea v-model="CurrentTicket.Description" :label="'發送條件說明'" rows="3" />
        <CSelect :label="'發送時機'" :options="EventTriggerIssueOptions" v-model="CurrentTicket.Event.TriggerIssue" :value.sync="CurrentTicket.Event.TriggerIssue" :placeholder="$t('Global.PleaseSelect')" @change="CurrentTicket.Variable.QuantityType = 'CUSTOM'" />
        <CSelect :label="'發送數量'" :options="[{label: '依照結帳商品個數發送', value: 'OrderQuantity', disabled: CurrentTicket.Event.TriggerIssue === 'NA'}, {label: '自訂發送數量', value: 'CUSTOM'}]" v-model="CurrentTicket.Variable.QuantityType" :value.sync="CurrentTicket.Variable.QuantityType" :placeholder="$t('Global.PleaseSelect')"/>
        <CInput v-if="CurrentTicket.Variable.QuantityType === 'CUSTOM'" v-model="CurrentTicket.Variable.Quantity" :label="'自訂發送數量'" type="number" />
        <div class="form-group">
          <label class="d-block">{{ $t('Offer.StartTime') }}</label>
          <v-date-picker v-model="CurrentTicket.StartTime" mode="dateTime" is24hr>
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </div>
        <div class="form-group">
          <label class="d-block">{{ $t('Offer.EndTime') }}</label>
          <v-date-picker v-model="CurrentTicket.EndTime" mode="dateTime" is24hr>
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </div>
        <div class="form-group">
          <label class="d-block">{{ $t('Offer.Status') }}</label>
          <CSwitch color="success" :checked.sync="StatusSwitch"/>
        </div>
      </div>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="CreateModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="Save()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="false">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false;ChooseID = []" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false;ChooseID = []" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "發送抽獎券"
}
}
</route>

<script>
export default {
  name: 'RaffleTicket',
  layout: 'manage',
  components: {
  },
  data () {
    return {
      List: [],
      ChooseID: [],
      CheckAllValue: false,
      AlertModel: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Action: 'Edit',
      CurrentTicket: null,
      FilterModel: false,
      ShowFilter: 'All',
      Order: 'nto',
      PageList: [],
      Loading: false,
      Submit: false,
      CreateModal: false,
      StatusSwitch: true,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: '發送條件名稱' },
        { key: 'Event', label: '發送條件' },
        { key: 'TimeRange', label: '生效期間' },
        { key: 'Status', label: this.$t('Offer.Status') },
        { key: 'Action', label: '' }
      ]
    },
    StatusOptions() {
      return Object.keys(this.$t('Offer.StatusOptions')).filter(type => type !== 'E').map(type => {
        return {
          value: type,
          label: this.$t('Offer.StatusOptions')[type]
        }
      })
    },
    EventTriggerIssueOptions() {
      return Object.keys(this.$t('Offer.EventTriggerRaffleIssueOptions')).map(type => {
        return {
          value: type,
          label: this.$t('Offer.EventTriggerRaffleIssueOptions')[type]
        }
      })
    },
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      const Data = {
        Taxonomy: 'RaffleTicket',
        Page: this.Pagination.Page,
        PerPage: this.Pagination.PerPage,
        Order: 'nto',
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/manage/list',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.List = data.Data.map(item => {
          if (item.Status === 'Y') {
            if (this.$dayjs().unix() > this.$dayjs(item.EndTime).unix() || this.$dayjs().unix() < this.$dayjs(item.StartTime).unix()) {
              item.Status = 'E'
            } else {
              item.Status = 'U'
            }
          }
          return item
        })
        this.Pagination = data.Pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseID = this.List.map(data => {
          return data._id
        })
      } else {
        this.CheckAllValue = false
        this.ChooseID = []
      }
    },
    DeleteModal (ID) {
      this.ChooseID = [ID]
      this.AlertModel = true
    },
    IssueTicket (offer) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Info'),
        text: `發送抽獎券「${offer.Name}」給指定會員。`,
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Send'),
        cancelButtonText: this.$t('Global.Cancel'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.Submit = true
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            endPoint: '/import',
            url: '/offer/issueRaffleTicket',
            method: 'post',
            data: {
              Taxonomy: 'RaffleTicket',
              Offer: offer,
              Email: result.value
            }
          }).then(() => {
            this.$Progress.finish()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Offer/IssueSuccess'),
              type: 'success'
            })
          }).catch((err) => {
            this.$Progress.fail()
            this.Submit = false
            this.AlertModel = false
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Offer/IssueFail') + err.msg,
              type: 'error'
            })
          })
        }
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/delete',
          Data: {
            Taxonomy: 'RaffleTicket',
            ID: this.ChooseID
          }
        }
      }).then(() => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.ChooseID = []
        this.CheckAllValue = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Offer/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Offer/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    AddIssueEvent () {
      this.CurrentTicket = {
        Taxonomy: 'RaffleTicket',
        Name: '發送事件-' + this.$dayjs().format('YYYY-MM-DD'),
        Description: '',
        Slug: Math.random().toString(36).substring(3, 8).toUpperCase(),
        Offer: {
          Target: 'NA',
          DiscountType: 'NA',
          FreeLogisticsFee: false,
          FreeServiceFee: false
        },
        Event: {
          TriggerIssue: 'MemberPayment',
          TriggerPage: [
            {
              Name: this.$t('Offer.AllUsagePage'),
              Slug: "ALL"
            }
          ],
        },
        Condition: [],
        TargetGroup: {
          Type: 'AllMember',
          List: []
        },
        UsagePage: [
          {
            Name: this.$t('Offer.AllUsagePage'),
            Slug: "ALL"
          }
        ],
        Variable: {
          QuantityType: 'OrderQuantity',
          Quantity: 1
        },
        Status: 'Y',
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD 00:00:00')).unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'month').format('YYYY-MM-DD 23:59:59')).unix() * 1000
      }
      this.CreateModal = true
      this.Action = 'Add'
    },
    EditIssueEvent (index) {
      this.CurrentTicket = this.List[index]
      delete this.CurrentTicket.__v
      this.StatusSwitch = this.CurrentTicket.Status === 'Y'
      this.CreateModal = true
      this.Action = 'Edit'
    },
    Save() {
      if (this.CurrentTicket.Name === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/NameEmpty'),
          type: 'warn'
        })
        return
      }
      if (this.CurrentTicket.Slug === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/SlugEmpty'),
          type: 'warn'
        })
        return
      }
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Offer/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        this.CreateModal = false
        this.GetList()
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Offer/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.CurrentTicket.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/add',
          Data: {
            ...this.CurrentTicket,
            StartTime: this.$dayjs(this.CurrentTicket.StartTime).unix(),
            EndTime: this.$dayjs(this.CurrentTicket.EndTime).unix(),
            Status: this.StatusSwitch === true ? 'Y' : 'N'
          }
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Offer/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.CurrentTicket.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      const UpdateData = {
        ...this.CurrentTicket,
        StartTime: this.$dayjs(this.CurrentTicket.StartTime).unix(),
        EndTime: this.$dayjs(this.CurrentTicket.EndTime).unix(),
        Status: this.StatusSwitch === true ? 'Y' : 'N'
      }
      delete UpdateData._id
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/edit',
          Data: {
            Taxonomy: 'RaffleTicket',
            ID: this.CurrentTicket._id,
            UpdateData
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Offer/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
  }
}
</script>

<style>
#RaffleTicketList thead th:first-child {
  text-align: center;
}
#RaffleTicketList tbody td:first-child {
  vertical-align: middle;
}
</style>
